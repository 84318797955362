import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import HousewareWidget from "~sections/featured-story/components/CS-widget/houseware"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Houseware() {
  return (
    <>
    <Helmet>
    <title>Houseware Books 10X More Outbound Calls Using We-Connect</title>
    <meta name="description" content="As Houseware grew, manual outreach no longer became a feasible option. Instead, the company trusted We-Connect to automate their lead generation and messaging."/>
    <meta property="og:title" content="Houseware Books 10X More Outbound Calls Using We-Connect"/>
    <meta property="og:description" content="As Houseware grew, manual outreach no longer became a feasible option. Instead, the company trusted We-Connect to automate their lead generation and messaging."/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/houseware"/>
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Houseware Books 10X More Outbound Calls Using We-Connect"/>
    <meta property="twitter:description" content="As Houseware grew, manual outreach no longer became a feasible option. Instead, the company trusted We-Connect to automate their lead generation and messaging."/>
    <meta property="twitter:image" content="https://we-connect.io/houseware-books-10X-more-outbound-calls-using-we-connect.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HousewareWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}