import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import Footer from "./style"
import logo from '../../../assets/image/logo/logo.svg'
import { AnchorLink } from "gatsby-plugin-anchor-links";
const today = new Date();
export default function FooterOne(){
return(
    <Footer backgroundColor="#f3f8fe">
    <Container>
        <Footer.Box pbXL="95px">
        <Row>
            <Col xs="12" className="col-xl-3 col-lg-3 col-md-8 col-xs-12">
            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Footer.Link  to="/">
                        <img src={logo} alt="logo" />
                    </Footer.Link>
                </Footer.Box>
                <Footer.Text mb="20px">
                Automate LinkedIn prospecting.<br className="d-none d-xl-block d-lg-block" />
                Discover new opportunities, generate more leads<br className="d-none d-lg-none d-xl-none" /> &amp; recruit right talent.<br className="d-none d-lg-block d-xl-block" />
                </Footer.Text>
            <Footer.Box mb="20px">
            <Footer.SocialShareItem>
                <a href="https://www.facebook.com/weconnect.io/" target="_blank">
                <i className="fab fa-facebook" />
                </a>
            </Footer.SocialShareItem>
           <Footer.SocialShareItem>
                <a href="https://twitter.com/weconnect_io" target="_blank">
                <FontAwesomeIcon icon={faXTwitter} />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.linkedin.com/company/weconnect-io" target="_blank">
                <i className="fab fa-linkedin" />
                </a>
            </Footer.SocialShareItem>
           <Footer.SocialShareItem>
                <a href="https://www.instagram.com/weconnect_io/" target="_blank">
                <i className="fab fa-instagram" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.youtube.com/@we-connect" target="_blank">
                <i className="fab fa-youtube" />
                </a>
            </Footer.SocialShareItem>
            </Footer.Box>
        

            <Footer.Text>Connect.Engage.Expand.</Footer.Text>

            </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
            <Row>

                <Col xs="6" className="col-xl-4 col-lg-4 col-md-4 col-xs-4">
                <Footer.Widgets>
                    <Footer.Title>Platform</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <AnchorLink to="/linkedin-automation" >
                                LinkedIn Automation
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                        <AnchorLink to="/email-automation" >
                                Email Automation
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/ai-digest">AI Assist
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/features">Features
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="https://developers.we-connect.io/" target="_blank">Developers</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/free-email-signature-generator">Email Signature Generator</AnchorLink>
                        </Footer.ListItems>
                        
                    </Footer.List>
                </Footer.Widgets>
                </Col>

                <Col xs="6" className="col-xl-4 col-lg-4 col-md-4 col-xs-4">
                <Footer.Widgets>
                    <Footer.Title>Company</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <Link href="/customer-stories" >
                                Customer Stories
                            </Link>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/agency">Agency
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/affiliates">Affiliates
                            </AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <Link to="/whitelabel">Whitelabel</Link>
                        </Footer.ListItems>
                        <Footer.ListItems>
                        <a href="https://support.we-connect.io" target="_blank">Knowledge Base</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <Link to="/compare-we-connect-to-competitors">Comparison</Link>
                        </Footer.ListItems>
                        
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                <Col xs="6" className="col-xl-4 col-lg-4 col-md-4 col-xs-4">
                <Footer.Widgets>
                    <Footer.Title>Legal</Footer.Title>
                    <Footer.List>
                        
                        <Footer.ListItems>
                            <AnchorLink to="/privacy">Privacy
                            Policy</AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/terms">Terms &amp;
                            Conditions</AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/gdpr">GDPR</AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/dpa">DPA</AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/usage-policy">Usage Policy</AnchorLink>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <AnchorLink to="/bounty-program">Security Bounty</AnchorLink>
                        </Footer.ListItems>
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                </Row>
                </Col>
                <Col xs="12" className="col-xl-3 col-lg-3 col-md-12 col-xs-12">
                <Row>
                <Col xs="12" className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <Footer.Widgets>
                        <Footer.Title>Recent Blogs</Footer.Title>
                        <Footer.Address className="widgets-address">
                            <Footer.AddressItem>
                            <a href="https://we-connect.io/blog/mastering-lead-generation-with-linkedins-advanced-search/" target="_blank">
                                <span>Mastering Lead-Gen with LinkedIn’s Advanced Search
                                </span></a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <a href="https://we-connect.io/blog/writing-an-effective-linkedin-call-to-action/" target="_blank">
                                <span> Writing an Effective Call-To-Action (With Examples)</span> </a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <a href="https://we-connect.io/blog/safely-automate-linkedin-lead-generation-in-2023/" target="_blank"><span>
                                12 Tips To Safely Automate LinkedIn Lead-Generation</span></a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <a href=" https://we-connect.io/blog/linkedin-social-selling-index/" target="_blank"><span>
                                LinkedIn Social Selling Index: Your Guide to LinkedIn Sales Success</span></a>
                            </Footer.AddressItem>
                        </Footer.Address>
                    </Footer.Widgets>
                </Col>

            </Row>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© {today.getFullYear()} We-Connect. All Rights Reserved.</Footer.CopyrightText>
        
        </Footer.Copyright>
    </Container>
    </Footer>
)
}
