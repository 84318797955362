import styled from 'styled-components/macro';
import { Link } from '~components';
import {
    Box,
    Heading,
    Button,
    Paragraph
} from '~styled';
const Widget = styled(Box)
`
padding-top: 20px;
padding-bottom: 20px;
@media (min-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 100px;
}
`
Widget.Image = styled(Box)`
  position: relative;
  margin-bottom: 40px;
  z-index: 1;

  @media (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 55px;
  }
  img {
    border-radius: 12px;
  }
`

Widget.Block = styled(Box)`
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 132px;
    padding-bottom: 135px;
  }
`
Widget.Title = styled(Heading)
`
    font-size: 32px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    margin-top: 15px;
    @media (min-width:768px) {
        font-size: 28px;
    }

    @media (min-width:992px) {
        font-size: 30px;
    }
`
Widget.Company = styled(Heading)
`
    font-size: 24px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    @media (min-width:768px) {
        font-size: 18px;
    }

    @media (min-width:992px) {
        font-size: 24px;
    }
`
Widget.Heading = styled(Heading)`
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

  @media (min-width: 480px) {
    font-size: 38px;
  }

  @media (min-width: 768px) {
    font-size: 48px;
  }

  @media (min-width: 992px) {
    font-size: 43px;
  }
`
Widget.Button = styled(Link)`
    align-items: center;
    transition:.4s;
    margin-bottom: 40px;
    border-radius: 50px;
    padding: 16px 15px;
    margin-top:10px;
    min-width: 137px;
    margin-left: 30px;
    &:hover{
    transform: translateY(-5px);
    

  }
`
Widget.BackButton = styled(Button)`
    display: block;
    align-items: center;
    transition:.4s;
    margin-bottom: 40px;
    &:hover{
    transform: translateY(-5px);

  }
`

Widget.Text = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
margin-top: 30px;
@media (min-width: 992px){
    font-size: 18px;
}
`
Widget.Para = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`
Widget.ParaTwo = styled(Paragraph)`
  font-size: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`
Widget.SubPara = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #262729;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-top: 30px;
  margin-bottom: 12px;
`
Widget.MiniHeading = styled(Paragraph)`
font-size: 16px;
font-weight: 500;
color: #000;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;
`

Widget.Box = styled(Box)
``
Widget.Card = styled(Box)
`
border-radius: 5px;
padding: 20px;
margin-left: 30px;
margin-right: 30px;
margin-bottom: 30px;
box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.1);
background-color: #F3F8FE;
    p a{
        font-size: 16px;
        font-weight: 500;
    }
    .web{
      color: #262729;
    }
    .rsc{
      margin-top: 0px;
    }
`
Widget.CardTwo = styled(Box)
`
border-radius: 5px;
padding: 20px;
margin-bottom: 20px;
margin-top: 60px;
box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.1);
background-color: #F3F8FE;
    
`

export default Widget;