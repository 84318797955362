import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import { SuperTag, Link } from '~components';
import VideoJSTwo from '../Videojs/VideoJSTwo';

export default function HousewareWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-5 col-lg-5 col-md-8 col-xs-10">
    <Widget.Image>
    <VideoJSTwo videoId="0aevc6qgrn" wrapper="wistia-player-container-4"/>
    </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="Houseware Books 10X More Outbound Calls Using <br/> We-Connect"/> </Widget.Heading>
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Houseware</Widget.Company>
        <Widget.Text>Houseware is a product and analytics solution that helps large-scale consumer businesses make informed decisions using quantitative data.
Industry: Product marketing & analytics</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Product marketing & analytics</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text> 11-50</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Company growth leads to outsourcing</b></Widget.Title>
        <Widget.Text>LinkedIn has been a primary channel for Houseware since the company was founded in 2021. Even in its infancy, co-founder and CEO, Divyansh Saini, was reaching out to potential partners to advise him on how to build the right product and solve the right problem.
        </Widget.Text>
        <Widget.Text>
        “I would manually reach out to people,” Divyansh explained. “As the company grew, I realized that that process was not going to scale very well.” Divyansh realized his team needed to reach out to more people using a targeted and analytical approach. That’s when Houseware stumbled across We-Connect. 
        </Widget.Text>
        <Widget.Title as="h2"><b>Building a funnel for long-term success</b></Widget.Title>
        <Widget.Text>Houseware has leveraged We-Connect in two major ways. The first is through outreach to a narrow ideal customer profile. Divyansh’s team qualifies prospects through technographical, geographical, and demographic criteria. After importing lists of these prospects to We-Connect, Divyansh invites them to his network and shares industry-specific content of value with them. 
        </Widget.Text>
        <Widget.Text>
        “There’s a very thin percentage of people who are out there buying software,” Divyansh said. Because of that, Houseware established a 12-month cycle through We-Connect with a different touch point each month.
        </Widget.Text>

        <Widget.ParaTwo>“The ease of setting up a campaign is what I find to be the most snazzy part of We-Connect.”</Widget.ParaTwo>
        <Widget.SubPara> <b>Divyansh Sani</b>, CEO of Houseware</Widget.SubPara>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}